<template>
  <div class="grid admintext-demo">
    <Toolbar class="toolbar_top">
      <template v-slot:start>

        <div class="my-2">
          <h3>{{ t('customerManagement') }}</h3>
        </div>
      </template>
    </Toolbar>
    <div class="col-12 ">
      <div class="card">
        <Toast/>
        <Toolbar class="block_add_btn">
          <template v-slot:start>
            <div class="my-2">
              <Button :label="t('addACustomer')" icon="pi pi-plus" class="add-text p-button-success mr-2"
                      @click="openNewCustomer()"/>
            </div>
          </template>
        </Toolbar>
        <DataTable :value="customersSites" dataKey="id" responsiveLayout="scroll" :paginator="true"
                   :rows="30" :totalRecords="totalItemsCustomers" :loading="loadingCustomer"
                   class="p-datatable-gridlines table-filter-texte"
        >
          <!--          @page="onPage($event)"-->
          <!--          <Column headerStyle="width: 3rem"/>-->
          <Column field="name" header="Id">
            <template #body="slotProps">
              {{ slotProps.data.id }}
            </template>
          </Column>
          <Column field="name" header="Code">
            <template #body="slotProps">
              {{ slotProps.data.code }}
            </template>
          </Column>
          <Column field="name" :header="t('wording')">
            <template #body="slotProps">
              {{ slotProps.data.name }}
            </template>
          </Column>
          <Column field="name" :header="t('reference')">
            <template #body="slotProps">
              {{ slotProps.data.reference }}
            </template>
          </Column>
          <Column header="Logo">
            <template>

            </template>
          </Column>
          <Column :header="t('CopyTheBase')">
            <template>
            </template>
          </Column>
          <Column :header="t('update')">
            <template #body="slotProps">
              <div class="flex">
                <Button icon="pi pi-pencil" class="button-table  mr-2" @click="openNewCustomer(slotProps.data)"
                        v-tooltip.top="{value:t('edit'), class: 'editBtn'}"/>
                <Button icon="pi pi-folder-open" class="btn_folder button-table  mr-2"
                        @click="detailsCustomer(slotProps.data)"
                        v-tooltip.top="{value:t('details'), class: 'editBtn'}"/>
              </div>

            </template>
          </Column>


        </DataTable>
      </div>
    </div>
    <Dialog v-model:visible="customerDialog" :style="{width: '900px'}" :header="titleModalCustomer" :modal="true"
            class="modal_general p-fluid">
      <TabView class="tabs_texte_admin tabview-custom">
        <TabPanel>
          <template #header>
            <span>Structure</span>
          </template>
          <div class="formgrid grid">
            <div class="field col">
              <label for="titre">Code* :</label>
              <InputText id="name" v-model.trim="customer.code" required="true" autofocus
                         :class="{'p-invalid': submitted && !customer.code}"/>
              <small class="p-invalid" v-if="submitted && !customer.code">{{ t('codeRequired') }}</small>
            </div>
            <div class="field col">
              <label for="titre">{{ t('reference') }}* :</label>
              <InputText id="name" v-model.trim="customer.reference" required="true" autofocus
                         :class="{'p-invalid': submitted && !customer.reference}"/>
              <small class="p-invalid" v-if="submitted && !customer.reference">{{ t('referenceRequired') }}</small>
            </div>
          </div>


          <div class="formgrid grid">
            <div class="field col">
              <label for="titre">{{ t('wording') }} :</label>
              <InputText id="name" v-model.trim="customer.name" required="true" autofocus/>
            </div>
            <div class="field col">
              <label for="Priorite">{{ t('year') }} </label>
              <InputNumber id="name" v-model.trim="customer.year" required="true" autofocus/>

            </div>

          </div>


        </TabPanel>

        <TabPanel>
          <template #header>
            <span>Logo</span>
          </template>
          <div class="grid">
            <div class="col-12">
              <div class="card">
                <FileUpload name="demo[]" accept="image/*" :maxFileSize="1000000" @upload="onUpload"/>


              </div>
            </div>
          </div>

        </TabPanel>


      </TabView>

      <template #footer>
        <Button :label="t('cancel')" icon="pi pi-times" class="p-button-text event_remove" @click="hideDialog"/>
        <Button :label="t('next')" v-if="customer.id" icon="pi pi-check" class="p-button-text next_btn"
                @click="editCustomer()"/>

        <Button :label="t('next')" v-else icon="pi pi-check" class="p-button-text next_btn" @click="saveCustomer()"/>
      </template>
    </Dialog>
    <Toast/>
  </div>
</template>
<script>
import {Actions} from "@/store/enums/StoreEnums";
import {useStore} from "vuex";
import {FilterMatchMode} from "primevue/api";
import router from "@/router";
import {useToast} from "primevue/usetoast";
import {usePrimeVue} from "primevue/config";

export default {
  name: "Admin-customer",

  data() {
    return {
      submitted: false,
      customersSites: null,
      expandedRows: [],
      sitesByCustomer: null,
      siteDialog: false,
      customerDialog: false,
      site: {},
      customer: {},
      countries: null,
      rowCustomer: null,
      titleModalCustomer: null,
      nbPageCustomers: 1,
      totalItemsCustomers: '',
      loadingCustomer: true,
    }
  },
  categoriesTheme: [],

  created() {
    this.initFilters();
  },
  setup() {
    const primevue = usePrimeVue();
    const store = useStore();
    const toast = useToast();
    return {store, toast, primevue, t: primevue.config.StoreTranslation.t};
  },
  mounted() {
    this.getCustomers(1)
  },
  methods: {
    getCustomers(page = 1) {
      this.loadingCustomer = true
      this.customersSites = []
      this.store.dispatch(Actions.GET_CUSTOMERS_SITE_TREE, {'customHeader': true, page: page,}).then(data => {
        if (data) {
          this.loadingCustomer = false
          this.customersSites = data['hydra:member'];
          this.totalItemsCustomers = data['hydra:totalItems']
        }
      })
    },
    openNewCustomer(dataCustomer) {
      this.submitted = false;
      this.customerDialog = true;
      if (dataCustomer) {
        this.customer.code = dataCustomer.code
        this.customer.name = dataCustomer.name
        this.customer.reference = dataCustomer.reference
        this.customer.id = dataCustomer.id
        this.customer.year = dataCustomer.year
        this.titleModalCustomer = 'Editer un client'
      } else {
        this.titleModalCustomer = 'Ajouter un client'

      }
      // this.getCategoriesByTheme();
    },
    initFilters() {
      this.filters = {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
      }
    },


    saveCustomer() {
      this.submitted = true
      if (this.customer.reference && this.customer.code) {
        this.store.dispatch(Actions.ADD_CUSTOMER, this.customer).then(() => {
          this.toast.add({
            severity: 'success',
            detail: this.t('customerHasSuccessfullyAdded'),
            life: 3000
          });
          this.customerDialog = false
          this.getCustomers(1)
        })
      }
    },


    detailsCustomer(dataCustomer) {
      router.push({name: 'ui-ax-Admin-site-master', params: {idCustomer: dataCustomer.id}})

    },
    editCustomer() {
      this.submitted = true
      if (this.customer.reference && this.customer.code) {
        this.store.dispatch(Actions.PUT_CUSTOMER, this.customer).then(() => {
          this.toast.add({
            severity: 'success',
            detail: this.t('customerHasSuccessfullyModified'),
            life: 3000
          });

          this.customerDialog = false
          this.getCustomers(1)
        })
      }
    },
    hideDialog() {
      this.customerDialog = false
    },
    // onPage(event) {
    //   this.nbPageCustomers = event.page + 1
    //   this.getCustomers(this.nbPageCustomers);
    // },

  },
}
</script>

<style scoped lang="scss">
@import '../../../assets/demo/styles/badges.scss';

::v-deep(.p-datatable-frozen-tbody) {
  font-weight: bold;
}

::v-deep(.p-datatable-scrollable .p-frozen-column) {
  font-weight: bold;
}

::v-deep(.p-progressbar) {
  height: .5rem;
  background-color: #D8DADC;

  .p-progressbar-value {
    background-color: #607D8B;
  }
}
</style>
